export default [
  { name: 'Яндекс', url: '/partners_logo/yandex.png' },
  { name: 'mapbox', url: '/partners_logo/mapbox.png' },
  { name: 'autoru', url: '/partners_logo/autoru.png' },
  { name: 'pik', url: '/partners_logo/pik.png' },
  { name: 'dt', url: '/partners_logo/dt.png' },
  { name: 'mapsme', url: '/partners_logo/mapsme.png' },
  { name: 'youdrive', url: '/partners_logo/youdrive.png' },
  { name: 'gulag', url: '/partners_logo/gulag.png' },
];
